@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'fonts/meta-pro-cufonfonts-webfont/style.css';

@font-face {
  font-family: 'Interstate Mono - Blk';
  src: local('Interstate Mono - Blk'), url("fonts/Interstate Mono - Blk Regular.otf") format('opentype');
}
@layer base {
  @font-face {
    font-family: 'Interstate Mono - Blk';
    src: local('Interstate Mono - Blk'), url("fonts/Interstate Mono - Blk Regular.otf") format('opentype');
  }
  * {
    font-family: "Meta Pro Regular";
  }
}
body {
  margin: 0;
  font-family: "Interstate Mono - Blk";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
