/* Clases css de syntax highlighting para el editor de formulas */

/* El syntax highlighting para errores */
.token.error {
  background-color: #e53935;
  color: #ffffff;
}

/* El syntax highlighting para numeros */
.token.number {
	color:#e53935;
}

/* El syntax highlighting para parentesis */
.token.paren {
	color:#4735e5;
}

/* El syntax highlighting para funciones */
.token.fun {
	color:#e53935;
}

/* El syntax highlighting para operaciones */
.token.op {
  color: #156292;
}

.token.var {
  color: #156292;
}

.token.aux {
  color: #156292;
}

.token.comma {
  color: initial;
}

.token.di {
  color: #156292;
}